import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";

export class ClientModel {
    id?: number;
    first_name?: string;
    last_name: string;
    email?: string;
    address_1: string;
    address_2: string;
    post_code: string;
	reference_no: string
	company_name: string;
	vat_no: string;
	city: string;
	country: string;
	discount_rate: string;
	notes: string;
	uploads: any[];
	key_persons: any[];
	invoice_frequency: string;
	service_capabilities: any[];
  
    constructor(input?) {
      input = input || {};
      this.id = input.id || '';
      this.first_name = input.first_name || '';
      this.last_name = input.last_name || '';
      this.email = input.email || '';
      this.address_1 = input.address_1 || '';
      this.address_2 = input.address_2 || '';
      this.post_code = input.post_code || '';
      this.vat_no = input.vat_no || '';
	  this.notes = input.notes || '';
	  this.reference_no = input.reference_no || '';
	  this.discount_rate = input.discount_rate || '';
	  this.city = input.city || '';
	  this.country = input.country || '';
	  this.uploads = input.uploads || [];
	  this.company_name = input.company_name || '';
	  this.invoice_frequency = input.invoice_frequency || '';
	  this.service_capabilities = input.service_capabilities || [];
    }

	static getKeyPersonsFormGroup(key_persons?) {
		let fb: FormBuilder = new FormBuilder();
		return fb.group({
			first_name: new FormControl(key_persons ? key_persons.first_name : ''),
			last_name: new FormControl(key_persons ? key_persons.last_name : ''),
			phone: new FormControl(key_persons ? key_persons.phone : ''),
			phone_other: new FormControl(key_persons ? key_persons.phone_other : ''),
			email: new FormControl(key_persons ? key_persons.email : ''),
			address_1: new FormControl(key_persons ? key_persons.address_1 : ''),
			address_2: new FormControl(key_persons ? key_persons.address_2 : ''),
			post_code: new FormControl(key_persons ? key_persons.post_code : ''),
			city: new FormControl(key_persons ? key_persons.city : ''),
			country: new FormControl(key_persons ? key_persons.country : ''),
			type: new FormControl(key_persons ? key_persons.type : ''),
		});
	}

	static getFormGroup(input): FormGroup {
		let fb: FormBuilder = new FormBuilder();

		let KeyPersonsFormArray = new FormArray([]);
		if (input.key_persons) {
			for (let key_person of input.key_persons) {
				KeyPersonsFormArray.push(this.getKeyPersonsFormGroup(key_person));
			}
		}
	
		return fb.group({
			first_name: [input.first_name],
			last_name: [input.last_name],
			email: [input.email],
			address_1: [input.address_1],
			address_2: [input.address_2],
			post_code: [input.post_code],
			vat_no: [input.vat_no],
			notes: [input.notes],
			discount_rate: [input.discount_rate],
			invoice_frequency: [input.invoice_frequency],
			city: [input.city],
			country: [input.country],
			company_name: [input.company_name],
			reference_no: [input.reference_no],
			uploads: [{}],
			key_persons: KeyPersonsFormArray,
			service_capabilities :[input.service_capabilities ? input.service_capabilities.id : null],
		})
	}
	
  }
  