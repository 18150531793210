import { Routes } from '@angular/router';
//Layouts
import { 
  //CondensedComponent,
  BlankComponent,
  //CorporateLayout,
  SimplyWhiteLayout,
  //ExecutiveLayout,
  //CasualLayout ,
  //BlankCasualComponent,
  //BlankCorporateComponent,
  BlankSimplywhiteComponent
} from './@pages/layouts';
import { AuthGaurd } from './shared/guards/auth-guard.service';
import { RoleGuard } from './shared/guards/role-guard';


export const AppRoutes: Routes = [

  {
    path: '',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:''
    },
    component: SimplyWhiteLayout,
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'user',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_admin'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/user/user.module#UserModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'location',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'create_location'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/locations/locations.module#LocationModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'jobsheets',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'create_jobsheet'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/jobsheets/jobsheets.module#JobsheetsModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'jobsheets_conflict',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'create_jobsheet'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/jobsheets_clash/jobsheets_clash.module#JobsheetsClashModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'jobsheets_finished',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'create_jobsheet'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/jobsheets_finished/jobsheets_finished.module#JobsheetsFinishedModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'customer',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'create_customer'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/client/client.module#ClientModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'inventory',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'create_stock'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/inventory/inventory.module#InventoryModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'service_capability',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_service_capability'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/service_capability/service_capability.module#ServiceCapabilityModule',
    runGuardsAndResolvers: 'always'
  },
{
    path: 'attendance',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_employee_attendance'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/attendance/attendance.module#AttendanceModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'absence',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'create_absence'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/absence/absence.module#AbsenceModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'myabsence',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'request_absence'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/myabsence/myabsence.module#MyAbsenceModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'mobile_absence',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'request_absence'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/mobile_absence/mobile_absence.module#MobileAbsenceModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'mobile',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_my_jobsheet'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/mobile/mobile.module#MobileModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'settings',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_settings'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/settings/settings.module#SettingsModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'leave',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_employee_leave'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/leave/leave.module#LeaveModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'calendar',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'create_jobsheet'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/calendar/calendar.module#CalendarsModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'invoices',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_invoice'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/Invoices/invoices.module#InvoicesModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'profile',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:'view_user'
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/profile/profile.module#ProfileModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGaurd,RoleGuard],
    data: {
      permission:''
    },
    component: SimplyWhiteLayout,
    loadChildren: './view/dashboard/dashboard.module#DashboardModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'session',
    data: {
      permission:''
    },
    component: BlankSimplywhiteComponent,
    loadChildren: "./view/session/session.module#SessionModule"
  },
];
