import { Injectable } from '@angular/core';
//import * as io from 'socket.io-client';
import { Observable } from 'rxjs/Observable';
import * as Rx from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import { LocalStoreService } from './local-store.service';
import Pusher from 'pusher-js';
import { Utils } from './utils.service';
import { NotificationService } from './notification.service';
import { ActionModel, MessageService } from '../../@pages/components/message/message.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  pusher: any = "";

  constructor(
    private store: LocalStoreService,
    private notificationService: NotificationService,
    private messageService: MessageService) {
  }

  listenChannel(channelName, userId) {
    // let user: any = this.store.getItem('currentUser');
    // let token = user ? user['token'] : null;
    // this.pusher = new Pusher("ee3a22149604537a5d44", {
    //   cluster: 'eu',
    //   auth: {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       Accept: 'application/json'
    //     }
    //   },
    //   authEndpoint: environment.ws_url,
    //   forceTLS: true
    // });

    // let _this = this;
    // this.pusher.subscribe(channelName + userId);

    // // Pusher.log = msg => {
    // //   console.log(msg);
    // // };

    // let actions: ActionModel = {
    //   onAccept: {
    //     function: (event) => {
    //       console.log(event);
    //     },
    //     buttonText: "Ok"
    //   },
    //   onDecline: {
    //     function: (event) => {
    //       console.log(event);
    //     },
    //     buttonText: "Cancel"
    //   },
    //   onIgnore: {
    //     function: (event) => {
    //       console.log(event);
    //     },
    //     buttonText: null
    //   }
    // }

    // this.pusher.allChannels().forEach(channel => {
    //   console.log("Subscribe: ", channel.name);
    //   channel.bind('notification-receive', function (data) {
    //     _this.notificationService.notifications = [data.message, ..._this.notificationService.notifications];
    //     _this.notificationService.has_unread += 1;
    //     console.log(data);
    //     _this.messageService.sendNotification(data.message.description, 'indicator', null);
    //   });
    // });
  }

}