import { Component, OnInit, ViewChild, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { MessageService } from '../../../@pages/components/message/message.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Page } from '../../../shared/models/page.model';
import { isArray } from 'util';
import { ErrorHandlingService } from '../../../shared/services/error-handling.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { ClientService } from '../../../shared/services/client.service';
import { ClientModel } from '../../../shared/models/client.model';
import { UploadService } from '../../../shared/services/upload.service';
import { ServicecapabilityService } from '../../../shared/services/servicecapability.service';
import { ServicecapabilityModel } from '../../../shared/models/servicecapability.model';

@Component({
	selector: 'app-client-detail',
	templateUrl: './client-detail.component.html',
	styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit {

	@Input() external: boolean = false;
	@Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
	guest: ClientModel;
	form: FormGroup;
	roles: any[];
	uploadedfiles = [];
	rows = [];
	uploadID = '';
	@ViewChild('mdSlideUp', { static: true }) mdSlideUp: ModalDirective;
	errors: String[] = [];
	ColumnMode = ColumnMode;
	constructor(
		private cdr: ChangeDetectorRef,
		private clientService: ClientService,
		private scservice: ServicecapabilityService,
		private fb: FormBuilder,
		private router: Router,
		private snapshot: ActivatedRoute,
		private authService: AuthService,
		private messageService: MessageService,
		private uploadService: UploadService,
		private errorHandler: ErrorHandlingService,
		private _location: Location
	) { }

	ngOnInit() {
		let _this = this;
		this.createForm({});
		this.AllTypes();
		this.getserviceCapability();
		this.AddNewKP();
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.messageService.remove();
			}
		});
		this.snapshot.paramMap.subscribe(res => {
			if (res['params']['id'] && !_this.external) {
				this.loadclient(res['params']['id']);
			}
		})

		this.customerprice = this.servidA['price']
	}

	menuItems: any[] = [
		{ action: 'delete', message: 'Delete Guest', class: '', icon: 'fal fa-trash', permissions: ['delete_guest'] }
	];

	handleButtonPress(event) {
		switch (event) {
			case "delete":
				this.mdSlideUp.show();
				break;
			default:
				console.log(event);
				break;
		}
	}

	fileUpload(fileInput: any) {
		let _this = this;

		if (fileInput.target.files && fileInput.target.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e: any) {
				console.log(e.target.result);
				//$('#preview').attr('src', e.target.result);
				_this.isImageSaved = true;

				_this.cardImageBase64 = e.target.result;
				var fileName = _this.cardImageBase64;

				if (fileName.startsWith('data:image/') == true) {
					var fileExtension = fileName.split('data:image/').pop().split(';base64,/');
					var fileType = fileName.split('data:').pop().split(';base64,/');
				} else if (fileName.startsWith('data:application/') == true) {

					var fileExtension = fileName.split('data:application/').pop().split(';base64,');
					var fileType = fileName.split('data:').pop().split(';base64,');
				}


				console.log(fileExtension[0]);
				console.log(fileType[0]);
				// var image = [_this.cardImageBase64, fileType[0], fileExtension[0], 'test'];
				var jsonObj = {
					"data": _this.cardImageBase64,
					"file_type": fileType[0],
					"file_ext": fileExtension[0],
					"description": fileInput.target.files[0].name
				};

				_this.uploadService.create(jsonObj).subscribe(res => {
					//_this.user['upload'] = res['data']['upload'];
					//_this.userForm.get('upload').setValue(res['data']['upload']);
					_this.uploadID = res['data']['id'];
					console.log(_this.uploadID);
					_this.uploadedfiles.push(_this.uploadID);
					_this.messageService.success("File Uploaded.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });

				});

			}

			reader.readAsDataURL(fileInput.target.files[0]);
		}
	}
	idx = ''
	imgName = '';

	@ViewChild('DelModal', { static: true }) DelModal: ModalDirective;
	RemoveRowModal(id, idx) {
		console.log(id, idx);
		this.imgName = id;
		localStorage.setItem('idx', idx);
		this.DelModal.show();
	}

	RemoveRow() {
		this.idx = localStorage.getItem('idx');
		var _this = this;
		let temp = [...this.rows];
		var numberValue = Number(this.idx);
		temp.splice(numberValue, 1);
		this.rows = temp;
		this.uploadedfiles = [];
		console.log(this.rows);
		for (var uObject of this.rows) {
			//console.log(uObject['id'])
			_this.uploadID = uObject['id'];
			console.log(_this.uploadID);
			this.uploadedfiles.push(_this.uploadID);

		}
		this.DelModal.hide()
	}

	fp = ''
	Download(idx) {
		var _this = this;
		let temp = [...this.rows];
		this.fp = temp[idx]['file_path'];
		console.log(this.fp);
	}

	columnsDynamic = [
		{
			name: 'Uploaded By',
			prop: 'user',
			flexGrow: 2,
			sortable: true
		},
		{
			name: 'File Type',
			prop: 'file_type',
			flexGrow: 1,
			sortable: true
		},
		{
			name: 'File Name',
			prop: 'description',
			flexGrow: 2,
			sortable: true
		},
	]

	empname = []
	price
	ServicePrice(id, event: any) {
		console.log(id, event.target.value)
		if (event.target.value != '') {
			//this.empname.push(id, event.target.value);
			this.empname.push({
				'id': id,
				'price': event.target.value
			});


		}
		else {
			this.empname.splice(this.empname.indexOf(id), 1);
		}

		console.log(this.empname);

	}


	@ViewChild('loadData', { static: true }) loadDataModal: ModalDirective;
	loadSavedData() {
		if (localStorage.getItem("guest-create")) {
			this.createForm(JSON.parse(localStorage.getItem("guest-create")));
			// if (this.clientForm.get('phone').value) {
			//   this.clientForm.get('phone').setValue(this.clientForm.get('phone').value['nationalNumber'].replace(" ", ""));
			// }
		}
		this.form.markAllAsTouched();
		this.loadDataModal.hide();
	}

	deleteData() {
		localStorage.removeItem("guest-create");
		this.loadDataModal.hide();
	}

	_type = []
	AllTypes() {
		var _this = this;
		this.clientService.type({}).subscribe(res => {
			_this._type = res['data'];
		});
	}

	sendBack() {
		this._location.back();
	}

	page: Page[] = [new Page(), new Page(), new Page()];
	currentPage: number[] = [1, 1, 1];
	searchParams = [{ per_page: 1000, page: this.currentPage[0] }, { per_page: 1000, page: this.currentPage[1] }, { per_page: 1000, page: this.currentPage[2] }];

	markTouched() {
		this.form.markAsTouched();
	}

	imageError: string;
	isImageSaved: boolean;
	cardImageBase64: string;

	fileChangeEvent(fileInput: any) {
		this.imageError = null;
		if (fileInput.target.files && fileInput.target.files[0]) {
			// Size Filter Bytes
			const max_size = 20971520;
			const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
			const max_height = 15200;
			const max_width = 25600;

			if (fileInput.target.files[0].size > max_size) {
				this.imageError =
					'Maximum size allowed is ' + max_size / 1000 + 'Mb';

				return false;
			}

			// if (!includes(allowed_types, fileInput.target.files[0].type)) {
			//     this.imageError = 'Only Images are allowed ( JPG | PNG )';
			//     return false;
			// }
			const reader = new FileReader();
			reader.onload = (e: any) => {
				const image = new Image();
				image.src = e.target.result;
				image.onload = rs => {
					const img_height = rs.currentTarget['height'];
					const img_width = rs.currentTarget['width'];

					// console.log(img_height, img_width);


					if (img_height > max_height && img_width > max_width) {
						this.imageError =
							'Maximum dimentions allowed ' +
							max_height +
							'*' +
							max_width +
							'px';
						return false;
					} else {
						const imgBase64Path = e.target.result;
						this.cardImageBase64 = imgBase64Path;
						this.isImageSaved = true;
						this.cdr.detectChanges();
						// this.previewImagePath = imgBase64Path;
					}
				};
			};

			reader.readAsDataURL(fileInput.target.files[0]);
		}
	}

	removeImage() {
		this.cardImageBase64 = null;
		this.isImageSaved = false;
	}


	key_persons: FormArray;
	//   createForm(input?) {
	//     input = input || {};
	//     this.clientForm = this.fb.group({
	//       first_name: [input.first_name, Validators.required],
	//       last_name: [input.last_name, Validators.required],
	//       address_1: [input.address_1],
	//       address_2: [input.address_2],
	//       post_code: [input.post_code],
	//       town: [input.town],
	//       country: [input.country],
	//       //vat_no: [input.vat_no],
	// 	  company_name: [input.company_name],
	//       vat_no: [input.vat_no],
	//       phone_number: [input.phone_number]
	//     });
	//   }
	_sc: ServicecapabilityModel[] = [];
	servicecap = []
	getserviceCapability() {
		var _this = this;
		this.scservice.getAll({}).subscribe(res => {
			for (var uObject of res['data']) {
				_this._sc.push(new ServicecapabilityModel(uObject));
				this.servicecap.push(uObject['id']);
			}

			
			// for(let tes of this.servicecap){
			// 	if(this.servicecap == this.servidA['id']){
			// 		console.log('hey')
			// 		//this.customerprice = tes['price']
			// 	}
			// }
		});
	}

	createForm(input, form?) {
		input = input || {};
		form ? form : this.form = ClientModel.getFormGroup(input);
		if (!form) {
			this.key_persons = this.form.get('key_persons') as FormArray;
		}
	}

	openFileUpload(ele) {
		$(ele).click();
	}

	hasPermission(permission) {
		return this.authService.hasPermission(permission);
	}

	selectedKPID = null;
	AddNewKP() {
		var _this = this;
		_this.key_persons.push(ClientModel.getKeyPersonsFormGroup({
			key_person: this.selectedKPID,
			first_name: '',
			last_name: '',
			phone: '',
			phone_other: '',
			email: '',
			address_1: '',
			address_2: '',
			post_code: '',
			city: '',
			country: '',
		}));
	}

	RemoveKP(idx) {
		var _this = this;
		//array.splice(_this.vehicles.length-1, 0, 0);
		_this.key_persons.removeAt(idx);
		if (idx == 0) {
			this.AddNewKP();
		}
	}


	uploadFile(fileInput) {
		console.log(fileInput);
		if (fileInput.target.files && fileInput.target.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e: any) {
				console.log(e.target.result);
				//$('#preview').attr('src', e.target.result);
			}
			reader.readAsDataURL(fileInput.target.files[0]);
		}
	}

	isTouched(controlName) {
		if (this.form.get(controlName).touched && this.form.get(controlName).invalid) {
			return true;
		}
		else {
			return false;
		}
	}

	loading = {
		isFirstLoad: false,
		isLoading: false
	};


	servid
	servprice
	customerprice
	servidA = []
	loadclient(id) {
		let _this = this;

		if (!_this.loading.isFirstLoad) {
			_this.loading.isFirstLoad = true;
			_this.loading.isLoading = true;
		}
		this.clientService.get(id).subscribe(res => {
			_this.guest = res['data'];
			_this.rows = res['data']['uploads'];
			_this.servprice = res['data']['service_capabilities']
			this.servid = res['data']['service_capabilities']['id']
			_this.guest = res['data'];
			_this.createForm(_this.guest);
			_this.loading.isLoading = false;
			for (var uObject of res['data']['uploads']) {
				//console.log(uObject['id'])
				_this.uploadID = uObject['id'];
				//console.log(_this.uploadID);
				this.uploadedfiles.push(_this.uploadID);

				


			}

			for (let sc of _this._sc)
			{
				for (let serv of res['data']['service_capabilities']) {
					if(serv.id == sc.id)
					{
						sc.customer_price = serv.price;
					}
					// this.servid = serv['id'];
					// this.servidA.push({
					// 	'id': serv['id'],
					// 	'price': serv['price']
					// });
				}
			}

			
			console.log(this.servidA)
		},
			err => {
				_this.loading.isLoading = false;
				console.log(err);
			});
	}

	isChecked(id) {
		return this.servidA.some(item => item.id === id);
	}

	delete() {
		let _this = this;
		this.isLoading = true;
		this.clientService.delete(this.guest.id).subscribe(res => {
			this.router.navigate(['/guest']);
			this.messageService.remove();
			this.messageService.success("guest Deleted.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
			_this.isLoading = false;
		},
			err => {
				this.messageService.remove();
				this.messageService.error(this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
				console.log(err);
				_this.isLoading = false;
			});
	}

	showSlideUp() {
		this.mdSlideUp.show();
	}

	@Input() isLoading: boolean = false;

	save() {
		let _this = this;

		_this.form.get('uploads').setValue(this.uploadedfiles);

		let scArr = [];

		for(let sc of this._sc)
		{
			if(sc.customer_price > 0)
			{
				scArr.push({
					"id" : sc.id,
					"price" : sc.customer_price
				});
			}
		}

		_this.form.get('service_capabilities').setValue(scArr);
		if (this.isLoading) {
			return;
		}

		this.form.updateValueAndValidity();
		if (this.form.invalid) {
			this.form.markAllAsTouched();
			this.isLoading = false;
			return;
		}

		localStorage.removeItem("customer-create");

		_this.isLoading = true;

		let rawValue = this.form.getRawValue();
		this.form.markAsUntouched();
		this.form.updateValueAndValidity();
		if (this.guest) {
			this.clientService.edit(this.guest.id, rawValue).subscribe(res => {
				this.router.navigate(['/customer']);
				this.messageService.success("Customer Updated.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
				console.log(res['data']);
				_this.createForm(res['data']);
				console.log(_this.form);
				_this.isLoading = false;
			},
				err => {
					_this.errors = _this.errorHandler.getErrors(err);
					this.messageService.remove();
					this.messageService.error(_this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
					console.log(err);
					_this.isLoading = false;
				});
		}
		else {
			this.clientService.create(rawValue).subscribe(res => {
				if (_this.external == true) {
					_this.saveEvent.emit(res['data']);
				}
				else {
					this.router.navigate(['/customer']);
				}
				this.messageService.remove();
				this.messageService.success("Customer Added.", { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
				_this.isLoading = false;
			},
				err => {
					_this.errors = _this.errorHandler.getErrors(err);
					this.messageService.error(_this.errorHandler.getErrors(err)[0], { Position: 'bottom-right', Style: 'simple', Duration: 5000 });
					console.log(err);
					_this.isLoading = false;
				});
		}
	}


}
