import { Injectable } from '@angular/core';

@Injectable()
export class RoleService {
	private roles: any = {
		1: {
		  role: 'Administrator',
		  defaultRoute: '/mobile'
		},
		2: {
		  role: 'Cleaner',
		  defaultRoute: '/mobile'
		},
		3: {
			role: 'User',
			defaultRoute: '/mobile'
		  },
	   
	  };
	
	  constructor() { }
	
	  getDefaultRoute(role: number): string{
		// //return "/user";
		// let route = this.menuService.menuItems[0].routerLink ? this.menuService.menuItems[0].routerLink : this.menuService.menuItems[0].submenu[0].routerLink;
		// return route;
		// //return this.roles[role].defaultRoute;
		return this.roles[role].defaultRoute;
	  }
}
