import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private http : HttpClient
  ) { }

  getAll(searchParams){
	return this.http.get(`${environment.JlUrl}/customer`,{params:searchParams});
  }

  get(id){
	return this.http.get(`${environment.JlUrl}/customer/`+id);
  }

  edit(id,formData){
	return this.http.put(`${environment.JlUrl}/customer/`+id,formData);
  }

  create(formData){
	return this.http.post(`${environment.JlUrl}/customer`,formData);
  }

  delete(id){
	return this.http.delete(`${environment.JlUrl}/customer/`+id);
  }

  reference(reference){
	return this.http.get(`${environment.JlUrl}/customer/reference/`+reference);
  }

  type(formData){
	return this.http.get(`${environment.baseUrl}/customer/type`,formData);
  }


}