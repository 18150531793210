import { Component, OnInit, OnDestroy, ViewChild, Input, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { pagesToggleService } from '../../services/toggler.service';
import { Router, Event, NavigationEnd, NavigationStart } from '@angular/router';
import * as $ from 'jquery';
declare var pg: any;

@Component({
	selector: 'root-layout',
	templateUrl: './root.component.html',
	styleUrls: ['./root.component.scss']
})
export class RootLayout implements OnInit, OnDestroy {
	@ViewChild('root', { static: false }) root;
	layoutState: string;
	extraLayoutClass: string;
	_boxed: boolean = false;
	_menuPin: boolean = false;
	_enableHorizontalContainer: boolean;
	_pageContainerClass = '';
	_contentClass = '';
	_footer: boolean = true;
	_menuDrawerOpen: boolean = false;
	//Mobile
	_secondarySideBar: boolean = false;
	//Mobile
	_mobileSidebar: boolean = false;
	//Mobile
	_mobileHorizontalMenu: boolean = false;
	_pageTitle: string;
	//Sub layout - eg: email
	_layoutOption: string;
	_subscriptions: Array<Subscription> = [];
	_layout;
	@Input()
	public contentClass: string = '';

	@Input()
	public pageWrapperClass: string = '';

	@Input()
	public footer: boolean = true;


	menuLinks = [
		//NOTE - UNCOMMENT THE FILTER IN SIMPLYWHITE COMPONENT
		{
			label: 'Dashboard',
			iconType: 'fal',
			iconName: 'fa-tachometer',
			routerLink: 'dashboard',
			roles: 'view_dashboard',

		},
		{
			label: 'Calendar',
			routerLink: 'calendar',
			iconType: 'fal',
			iconName: 'fa-calendar',
			roles: 'create_jobsheet',
		},
		{
			label: 'Jobsheets',
			toggle: 'close',
			submenu_path: 'jobsheets',
			iconType: 'fal',
			iconName: 'fa-file-spreadsheet',
			roles: 'create_jobsheet',
			submenu: [
				{
					label: 'New Jobsheet',
					routerLink: 'jobsheets/new',
					iconType: 'fal',
					iconName: 'fa-file-spreadsheet',
					roles: 'create_jobsheet',
				},
				{
					label: 'All',
					routerLink: 'jobsheets',
					iconType: 'fal',
					iconName: 'fa-file-spreadsheet',
					roles: 'create_jobsheet',
				},
				{
					label: 'Conflict',
					routerLink: 'jobsheets_conflict',
					iconType: 'fal',
					iconName: 'fa-file-spreadsheet',
					roles: 'create_jobsheet',
				},
				{
					label: 'Finished',
					routerLink: 'jobsheets_finished',
					iconType: 'fal',
					iconName: 'fa-file-spreadsheet',
					roles: 'create_jobsheet',
				},
			]
		},

		{
			label: 'Work Location',
			routerLink: 'location',
			iconType: 'fal',
			iconName: 'fa-map',
			roles: 'create_location',
		},
		{
			label: 'Customer',
			routerLink: 'customer',
			iconType: 'fal',
			iconName: 'fa-user-tie',
			roles: 'create_customer',
		},
		{
			label: 'Invoices',
			routerLink: 'invoices',
			iconType: 'fal',
			iconName: 'fa-receipt',
			roles: 'view_invoice',
		},
		{
			label: 'My Jobsheets',
			routerLink: 'mobile',
			iconType: 'fal',
			iconName: 'fa-phone',
			roles: 'view_my_jobsheet',
		},
		{
			label: 'My Absence',
			routerLink: 'mobile_absence',
			iconType: 'fal',
			iconName: 'fa-phone',
			roles: 'request_absence',
		},
		{
			label: 'HR',
			toggle: 'close',
			submenu_path: 'hr',
			iconType: 'fal',
			iconName: 'fa-building',
			roles: 'create_absence',
			submenu: [
				{
					label: 'Attendance',
					routerLink: 'attendance',
					iconType: 'fal',
					iconName: 'fa-calendar-check',
					roles: 'view_employee_attendance',
				},
				{
					label: 'Absence',
					routerLink: 'absence',
					iconType: 'fal',
					iconName: 'fa-clock',
					roles: 'create_absence',
				},
				{
					label: 'My Absence',
					routerLink: 'myabsence',
					iconType: 'fal',
					iconName: 'fa-clock',
					roles: 'request_absence',
				}

			]
		},
		{
			label: 'Inventory',
			routerLink: 'inventory',
			iconType: 'fal',
			iconName: 'fa-box',
			roles: 'create_stock',
		},
		{
			label: 'Settings',
			toggle: 'close',
			submenu_path: 'settings',
			iconType: 'fal',
			iconName: 'fa-building',
			roles: 'view_settings',
			submenu: [
				{
					label: 'Leave',
					routerLink: 'leave',
					iconType: 'fal',
					iconName: 'fa-arrow-right',
					roles: 'view_employee_leave',
				},
				{
					label: 'Service Capability',
					routerLink: 'service_capability',
					iconType: 'fal',
					iconName: 'fa-chart-bar',
					roles: 'view_service_capability',
				},

				{
					label: 'System Users',
					routerLink: 'user',
					iconType: 'fal',
					iconName: 'fa-users',
					roles: 'view_user',
				},
				{
					label: 'General Settings',
					routerLink: 'settings',
					iconType: 'fal',
					iconName: 'fa-wrench',
					roles: 'view_settings',
				}
			]
		},


	];

	constructor(public toggler: pagesToggleService, public router: Router) {
		if (this.layoutState) {
			pg.addClass(document.body, this.layoutState);
		}

		// window.onbeforeunload = function() {
		//   if ($('form').hasClass("ng-touched") && !$('form').hasClass("exempt")) {
		//     //console.log(state.url);
		//     if (!confirm("You have unsaved data. Are you sure you'd like to discard it?")) {
		//       return false;
		//     }
		//   } 
		// }

		router.events.subscribe((event: Event) => {
			// if(event instanceof NavigationStart){
			//   if ($('form').hasClass("ng-touched") && !$('form').hasClass("exempt")) {
			//     console.log("has touched form!");
			//     //console.log(state.url);
			//     if (!confirm("You have unsaved data. Are you sure you'd like to discard it?")) {
			//       //event.preventDefault();
			//       router.events.
			//       return false;
			//     }
			//   }
			// }
			if (event instanceof NavigationEnd) {
				var root = this.router.routerState.snapshot.root;
				while (root) {
					if (root.children && root.children.length) {
						root = root.children[0];
					} else if (root.data) {
						//Custom Route Data here
						this._pageTitle = root.data['title'];
						this._layoutOption = root.data['layoutOption'];
						this._boxed = root.data['boxed'];
						break;
					} else {
						break;
					}
				}
				//Reset Any Extra Layouts added from content pages
				pg.removeClass(document.body, this.extraLayoutClass);
				//Close Sidebar and Horizonta Menu
				if (this._mobileSidebar) {
					this._mobileSidebar = false;
					pg.removeClass(document.body, 'sidebar-open');
					this.toggler.toggleMobileSideBar(this._mobileSidebar);
				}
				this._mobileHorizontalMenu = false;
				this.toggler.toggleMobileHorizontalMenu(this._mobileHorizontalMenu);
				//Scoll Top
				this.scrollToTop();
			}

			//Subscribition List
			this._subscriptions.push(
				this.toggler.pageContainerClass.subscribe(state => {
					this._pageContainerClass = state;
				})
			);

			this._subscriptions.push(
				this.toggler.contentClass.subscribe(state => {
					this._contentClass = state;
				})
			);

			this._subscriptions.push(
				this.toggler.bodyLayoutClass.subscribe(state => {
					if (state) {
						this.extraLayoutClass = state;
						pg.addClass(document.body, this.extraLayoutClass);
					}
				})
			);

			this._subscriptions.push(
				this.toggler.Applayout.subscribe(state => {
					this.changeLayout(state);
				})
			);

			this._subscriptions.push(
				this.toggler.Footer.subscribe(state => {
					this._footer = state;
				})
			);

			this._subscriptions.push(
				this.toggler.mobileHorizontaMenu.subscribe(state => {
					this._mobileHorizontalMenu = state;
				})
			);
		});
	}

	/** @function changeLayout
	 *   @description Add Document Layout Class
	 */
	changeLayout(type: string) {
		this.layoutState = type;
		if (type) {
			pg.addClass(document.body, type);
		}
	}

	/** @function removeLayout
	 *   @description Remove Document Layout Class
	 */
	removeLayout(type: string) {
		pg.removeClass(document.body, type);
	}

	ngOnInit() {
		console.log("Init Root");
	}

	ngOnDestroy() {
		for (const sub of this._subscriptions) {
			sub.unsubscribe();
		}
	}
	ngAfterViewInit() { }

	/** @function scrollToTop
	 *   @description Force to scroll to top of page. Used on Route
	 */
	scrollToTop() {
		let top = window.pageYOffset;
		if (top == 0) {
			let scroller = document.querySelector('.page-container');
			if (scroller) scroller.scrollTo(0, 0);
		} else {
			window.scrollTo(0, 0);
		}
	}

	/** @function openQuickView
	 *   @description Show Quick View Component / Right Sidebar - Service
	 */
	openQuickView($e) {
		$e.preventDefault();
		this.toggler.toggleQuickView();
	}

	/** @function openSearch
	 *   @description Show Quick Search Component - Service
	 */

	openSearch($e) {
		$e.preventDefault();
		this.toggler.toggleSearch(true);
	}

	/** @function toggleMenuPin
	 *   @description Permanently Open / Close Main Sidebar
	 */
	toggleMenuPin($e) {
		if (pg.isVisibleSm()) {
			this._menuPin = false;
			return;
		}
		if (this._menuPin) {
			pg.removeClass(document.body, 'menu-pin');
			this._menuPin = false;
		} else {
			pg.addClass(document.body, 'menu-pin');
			this._menuPin = true;
		}
	}

	/** @function toggleMenuDrawer
	 *   @description Open Main Sidebar Menu Drawer - Service
	 */
	toggleMenuDrawer() {
		this._menuDrawerOpen = this._menuDrawerOpen == true ? false : true;
		this.toggler.toggleMenuDrawer();
	}

	/** @function toggleMobileSidebar
	 *   @description Open Main Sidebar on Mobile - Service
	 */
	toggleMobileSidebar() {
		if (this._mobileSidebar) {
			this._mobileSidebar = false;
			pg.removeClass(document.body, 'sidebar-open');
		} else {
			this._mobileSidebar = true;
			pg.addClass(document.body, 'sidebar-open');
		}
		this.toggler.toggleMobileSideBar(this._mobileSidebar);
	}

	/** @function toggleHorizontalMenuMobile
	 *   @description Open Secondary Sidebar on Mobile - Service
	 */
	toggleSecondarySideBar() {
		console.log('hi');
		this._secondarySideBar = this._secondarySideBar == true ? false : true;
		this.toggler.toggleSecondarySideBar(this._secondarySideBar);
	}

	/** @function toggleHorizontalMenuMobile
	 *   @description Call Horizontal Menu Toggle Service for mobile
	 */
	toggleHorizontalMenuMobile() {
		this._mobileHorizontalMenu = this._mobileHorizontalMenu == true ? false : true;
		this.toggler.toggleMobileHorizontalMenu(this._mobileHorizontalMenu);
	}

	@HostListener('window:resize', [])
	onResize() {
		this.autoHideMenuPin();
	}

	//Utils
	autoHideMenuPin() {
		if (window.innerWidth < 1025) {
			if (pg.hasClass(document.body, 'menu-pin')) {
				pg.addClass(document.body, 'menu-unpinned');
				pg.removeClass(document.body, 'menu-pin');
			}
		} else {
			if (pg.hasClass(document.body, 'menu-unpinned')) {
				pg.addClass(document.body, 'menu-pin');
			}
		}
	}
}
