import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export class ServicecapabilityModel {
	id: string;
    price: string;
    title:string;
	is_hourly: string;
	description: string;
	customer_price: number;
 
    constructor(input?) {
      input = input || {};
	  this.id = input.id || '';
      this.price = input.price || '';
      this.title = input.title || '';
	  this.is_hourly = input.is_hourly || '';
	  this.description = input.description || '';
    }

	static getFormGroup(input): FormGroup {
		let fb: FormBuilder = new FormBuilder();
		return fb.group({
			id: [input.id],
			price: [input.price, Validators.required],
			title: [input.title],
			description: [input.description],
			is_hourly: [input.is_hourly]
		})
	}
  }
  